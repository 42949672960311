<template>
  <v-select
    :value="value"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :items="countries"
    item-value="name"
    :item-text="item => `${item.name} - ${item.description}`"
    clearable
    multiple
    return-object
  >
  </v-select>
</template>

<script>
import ApiService from "../../services/api.service";

export default {
  name: "CountriesField",
  props: {
    value: Array,
    available: Array,
    program: Object
  },
  data: () => ({
    countries: []
  }),
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    },
    fetchData() {
      if (this.available) {
        this.countries = this.available;
      } else if (this.program && this.program.programCountries) {
        this.countries = this.program.programCountries.map(programCountry => programCountry.country);
      } else {
        ApiService.get("/api/types/address/countries").then(({ data }) => {
          this.countries = data;
        });
      }
    }
  },
  watch: {
    available() {
      this.fetchData();
    }
  },
  mounted() {
    this.fetchData();
  }
};
</script>
